import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';

Vue.use(VeeValidate);

Validator
  .extend('smallcaps_underscores', {
    validate: (value) => ({
      valid: value.match(/^[a-z]+(_[a-z]+)*$/),
      data: {
        message: Vue.filter('translate')(value[value.length - 1] === '_'
          ? 'form_field_must_not_end_in_underscore'
          : 'form_field_only_small_caps_and_underscores'),
      },
    }),
    getMessage: (field, params, data) => data.message,
  });

Validator
  .extend('unique', {
    validate: (value, duplicateValues) => ({
      valid: !duplicateValues.some((duplicateValue) => duplicateValue === value),
    }),
    getMessage: (field) => Vue.filter('translate')('form_field_already_exists', {
      fieldName: field,
    }),
  });

Validator
  .extend('image_url', {
    validate: (value) => ({
      // eslint-disable-next-line
      valid: value.length <= 256 && value.match(/^https?:\/\/[a-zA-Z0-9-_~:?#[\]@!$&'()*+,;=\.\/%]{1,237}\.(png|jpg|jpeg|gif|svg)$/),
      data: {
        message: 'This field must be a valid image URL!',
      },
    }),
    getMessage: (field, params, data) => data.message,
  });

export default {};
