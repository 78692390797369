import Vue from 'vue';
import errorCodes from '@/utility/errorCodes';
import constants from './constants';

const { GROUP_GAMES_PAGE_SIZE } = constants;

export function removeGroupGames({
  games, selectedGameIds, excludedGameIds, areAllGamesSelected,
}) {
  return games.filter((game) => (areAllGamesSelected ? excludedGameIds.includes(game.id) : !selectedGameIds.includes(game.id)));
}

export function toggleGamesGroupMembership({
  games, selectedGameIds, excludedGameIds, action, areAllGamesSelected,
}) {
  return games.map((game) => {
    if (areAllGamesSelected ? !excludedGameIds.includes(game.id) : selectedGameIds.includes(game.id)) {
      return {
        ...game,
        inGroup: action === 'ADD',
      };
    }

    return game;
  });
}

export function toggleGamesIsLoading({
  games, selectedGameIds, excludedGameIds, isLoading, areAllGamesSelected,
}) {
  return games.map((game) => {
    if (areAllGamesSelected ? !excludedGameIds.includes(game.id) : selectedGameIds.includes(game.id)) {
      return {
        ...game,
        isLoading,
      };
    }

    return game;
  });
}

export function updateGetGroupGamesParams(params, page = null) {
  const localParams = structuredClone(params);

  if (params.isNextPage) {
    localParams.page = page + 1;
  }
  if (params.shouldRefetchAllPages) {
    localParams.page = 1;
    localParams.size = page * GROUP_GAMES_PAGE_SIZE;
  }

  const {
    isNextPage, shouldRefetchAllPages, isInitialFetch, ...rest
  } = localParams;

  return rest;
}

export function parseGroupGamesData({
  newData, prevData, isNextPage, shouldRefetchAllPages,
}) {
  if (shouldRefetchAllPages) {
    const pagination = {
      ...newData.pagination,
      size: GROUP_GAMES_PAGE_SIZE,
      page: prevData.pagination.page,
      pages: Math.ceil(newData.pagination.total / GROUP_GAMES_PAGE_SIZE),
    };

    return {
      games: newData.games.map((game) => ({
        ...game,
        isLoading: prevData.games.find((prevGame) => prevGame.id === game.id)?.isLoading ?? false,
      })),
      pagination,
    };
  }

  if (isNextPage) {
    return {
      pagination: newData.pagination,
      games: [...prevData.games, ...newData.games],
    };
  }

  return newData;
}

export function getGroupErrorTranslations(err, params = {}) {
  const { entityType } = params;
  const groupTypeTranslationKeys = {
    group: 'general_group',
    subgroup: 'general_subgroup',
  };

  switch (err.response?.data?.code) {
    case errorCodes.NONEXISTENT_ENTITY:
      return Vue.filter('translate')('session_expired_notification_info');

    case errorCodes.ER_DUP_ENTRY:
      return Vue.filter('translate')('entity_field_already_exists_notification_info', {
        entityName: Vue.filter('translate')(groupTypeTranslationKeys[entityType]),
        fieldName: Vue.filter('translate')('general_display_id'),
      });

    default:
      return err;
  }
}
