import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { sync } from 'vuex-router-sync';
import localStorage from '@/utility/localStorage';
import App from './App';
import './plugins';
import router from './router';
import store from './store';
import Integration from './services/integration';
import isIntegration from './utility/isIntegration';
import vuetify from './plugins/vuetify';
import './styles/main.scss';

if (process.env.VUE_APP_ERROR_TRACKER_ACTIVE === 'true') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    logErrors: true,
  });
}

Vue.config.productionTip = false;
sync(store, router);

Vue.filter('translate', (key, interpolationParams) => store.getters.translation(key, interpolationParams));

const bootstrapApp = ({ data } = {}) => {
  if (data) {
    if (data.user) {
      store.dispatch('setUser', data.user);
    }
    store.dispatch('setUserModules', data.modules);
  }

  store.dispatch('getInitialAppData').then(() => {
    new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }).catch((error) => { console.log(`***Error, getInitialData failed: ${error.message}`); });
};

if (isIntegration()) {
  Integration.init().then((message) => {
    bootstrapApp(message);
  });
} else {
  bootstrapApp({
    data: {
      user: JSON.parse(localStorage.getItem('user')) ?? {},
      modules: {
        companyAdmin: {
          view: true,
          edit: true,
        },
        tpaAggregators: {
          view: true,
          edit: true,
        },
        tpaProviders: {
          view: true,
          edit: true,
        },
        tpaGames: {
          view: true,
          edit: true,
        },
      },
    },
  });
}
