import axios from 'axios';
import { interceptor, responseInterceptor } from './interceptors';

const gravityBaseURL = process.env.VUE_APP_GRAVITY_API_BASEURL;

const gravityHttp = axios.create({
  baseURL: gravityBaseURL,
  timeout: 10000,
});

gravityHttp.interceptors.request.use(interceptor, (error) => Promise.reject(error));
gravityHttp.interceptors.response.use(responseInterceptor, (error) => Promise.reject(error));

const getResources = ({
  containerName,
  currentPage = 1,
  pageSize = 1000,
  extend = 'container',
}) => {
  const url = 'v1/cms/resources';

  return gravityHttp.get(url, {
    params: {
      containerName,
      currentPage,
      pageSize,
      extend,
    },
  }).then((res) => res.data);
};

export default {
  getResources,
};
