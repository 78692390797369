export default () => {
  const { ancestorOrigins } = window.location;
  const { self, top } = window;

  if (ancestorOrigins) {
    return !!ancestorOrigins[0];
  }

  if (self && top) {
    return window.self !== window.top;
  }

  return false;
};
