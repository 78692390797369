import http from './http';

const getSubgroups = ({ companyId, groupId, auditData }) => {
  const url = `casino/v2/admin/companies/${companyId}/groups/${groupId}/subgroups`;

  return http.get(url, auditData)
    .then((res) => res.data);
};

const editSubgroup = ({
  companyId, subgroupId, subgroup, auditData,
}) => {
  const url = `casino/v2/admin/companies/${companyId}/subgroups/${subgroupId}`;

  return http.put(url, subgroup, auditData)
    .then((res) => res.data);
};

const createSubgroup = ({ companyId, subgroup, auditData }) => {
  const url = `casino/v2/admin/companies/${companyId}/subgroups`;

  return http.post(url, subgroup, auditData)
    .then((res) => res.data);
};

const deleteSubgroup = ({ companyId, subgroupId, auditData }) => {
  const url = `casino/v2/admin/companies/${companyId}/subgroups/${subgroupId}`;

  return http.delete(url, auditData)
    .then((res) => res.data);
};

export default {
  getSubgroups,
  editSubgroup,
  createSubgroup,
  deleteSubgroup,
};
