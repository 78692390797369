import _capitalize from 'lodash/capitalize';
import http from './http';

const capitalizeTitle = (filterTypesArr) => filterTypesArr.map((filterTypeValue) => ({
  title: _capitalize(filterTypeValue.name),
  ...filterTypeValue,
}));

const getCompanies = () => {
  const url = '/casino/admin/companies';
  return http.get(url)
    .then((res) => capitalizeTitle(res.data.companies));
};

const getCategories = () => {
  const url = '/casino/admin/products/categories';
  return http.get(url)
    .then((res) => capitalizeTitle(res.data.categories));
};

const getAggregators = (companyId) => {
  const url = '/casino/admin/products/aggregators';

  return http
    .get(url, { headers: { 'X-Nsft-WebAPI-CompanyUuid': companyId } })
    .then((res) => capitalizeTitle(res.data.aggregators));
};

const getProviders = (companyId) => {
  const url = '/casino/admin/products/providers';

  return http
    .get(url, { headers: { 'X-Nsft-WebAPI-CompanyUuid': companyId } })
    .then((res) => capitalizeTitle(res.data.providers));
};

export default {
  getCompanies,
  getCategories,
  getAggregators,
  getProviders,
};
