import axios from 'axios';
import { interceptor } from './interceptors';

const baseURL = process.env.VUE_APP_FRASIER_API_BASEURL;

const http = axios.create({
  baseURL,
  timeout: 5000,
});

http.interceptors.request.use(interceptor, (error) => Promise.reject(error));

const getTranslations = (language = 'en') => {
  const mappedLanguage = language.split('-Latn')[0];
  const url = `/translations/${process.env.VUE_APP_FRASIER_DOMAIN}/locales/${mappedLanguage}`;
  return http.get(url).then((res) => res.data);
};

export default getTranslations;
