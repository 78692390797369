<template>
  <div class="loading-overlay"
       :class="{ visible: isLoaderVisible }">
    <div class="spin-loader" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AppLoader',
  computed: {
    ...mapState(['isLoaderVisible']),
  },
};
</script>

<style scoped lang="scss">
.loading-overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  background: transparentize(#fff, 0.7);
  transition: opacity 150ms ease-out;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  .spin-loader {
    width: 56px;
    height: 56px;
    border: 8px solid #0052ec;
    border-right-color: transparent;
    border-radius: 50%;
    animation: loader-rotate 1s linear infinite;

    &::after {
      content: "";
      width: 8px;
      height: 8px;
      background: #0052ec;
      border-radius: 50%;
      position: absolute;
      top: -1px;
      left: 33px;
    }
  }
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
