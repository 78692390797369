import games from './games';
import groups from './groups';
import subgroups from './subgroups';
import filters from './filters';
import tpagames from './tpagames';
import carousels from './carousels';
import resources from './resources';

export default {
  games,
  groups,
  subgroups,
  filters,
  tpagames,
  carousels,
  resources,
};
