import axios from 'axios';
import { tpaInterceptor } from './interceptors';

const baseURL = process.env.VUE_APP_TPA_MANAGEMENT_API_BASEURL;

const http = axios.create({
  baseURL,
  timeout: 5000,
});

http.interceptors.request.use(tpaInterceptor, (error) => Promise.reject(error));

const getAllTPAGames = ({ ...params }) => {
  const url = '/client/query/games';
  return http.get(url, {
    params,
  }).then((res) => res.data);
};

const getTPAAggregators = () => {
  const url = '/client/query/aggregators';

  return http.get(url).then((res) => res.data);
};

const getTPACategories = () => {
  const url = '/client/query/casino/categories';

  return http.get(url).then((res) => res.data);
};

const getTPAProviders = () => {
  const url = '/client/query/providers';

  return http.get(url).then((res) => res.data);
};

const getTPATypes = () => {
  const url = '/client/query/types';

  return http.get(url).then((res) => res.data);
};

const updateTPAGame = (game) => {
  const url = 'client/game';

  return http.patch(url, game).then((res) => res);
};

const registerTPAGame = (game) => {
  const url = 'client/game';

  return http.post(url, game).then((res) => res);
};

const registerMultipleTPAGames = (games) => {
  const parsedParams = { games };

  return http.post('client/games', parsedParams);
};

export default {
  getAllTPAGames,
  getTPAAggregators,
  getTPACategories,
  getTPAProviders,
  getTPATypes,
  updateTPAGame,
  registerTPAGame,
  registerMultipleTPAGames,
};
