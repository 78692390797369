import api from '../../api';
import mutationTypes from '../mutationTypes';
import { handleError, handleSuccess } from '../actions';

export default {
  state: {
    allTPAGames: [],
    filterConfig: {
      search: true,
      category: true,
      provider: true,
      aggregator: true,
    },
    tpaAggregators: [],
    tpaCategories: [],
    tpaProviders: [],
    tpaTypes: [],
  },

  getters: {
    tpaGames: (state) => state.allTPAGames,
    tpaFilterConfig: (state) => state.filterConfig,
  },

  actions: {
    getAllTPAGames: ({ commit }, params) => api.getAllTPAGames(params)
      .then((games) => commit(mutationTypes.SET_ALL_TPA_GAMES, games))
      .catch((err) => handleError({ commit }, err)),
    getTPAAggregators: ({ commit }) => api.getTPAAggregators()
      .then((aggregators) => commit(mutationTypes.SET_TPA_AGGREGATORS, aggregators.data))
      .catch((err) => handleError({ commit }, err)),
    getTPACategories: ({ commit }) => api.getTPACategories()
      .then((categories) => commit(mutationTypes.SET_TPA_CATEGORIES, categories.data))
      .catch((err) => handleError({ commit }, err)),
    getTPAProviders: ({ commit }) => api.getTPAProviders()
      .then((providers) => commit(mutationTypes.SET_TPA_PROVIDERS, providers.data))
      .catch((err) => handleError({ commit }, err)),
    getTPATypes: ({ commit }) => api.getTPATypes()
      .then((types) => commit(mutationTypes.SET_TPA_TYPES, types.data))
      .catch((err) => handleError({ commit }, err)),
    // eslint-disable-next-line no-empty-pattern
    updateTPAGame: ({}, params) => api.updateTPAGame(params),
    // eslint-disable-next-line no-empty-pattern
    registerTPAGame: ({}, params) => api.registerTPAGame(params),
    // eslint-disable-next-line no-empty-pattern
    registerMultipleTPAGames: ({}, params) => api.registerMultipleTPAGames(params),
    handleSuccessfulAction: ({ commit }, message) => handleSuccess({ commit }, message),
    handleErrorAction: ({ commit }, message) => handleError({ commit }, message),
  },

  mutations: {
    [mutationTypes.SET_ALL_TPA_GAMES](state, games) {
      state.allTPAGames = games;
    },
    [mutationTypes.SET_TPA_AGGREGATORS](state, aggregators) {
      state.tpaAggregators = aggregators;
    },
    [mutationTypes.SET_TPA_CATEGORIES](state, categories) {
      state.tpaCategories = categories;
    },
    [mutationTypes.SET_TPA_PROVIDERS](state, providers) {
      state.tpaProviders = providers;
    },
    [mutationTypes.SET_TPA_TYPES](state, types) {
      state.tpaTypes = types;
    },
  },
};
