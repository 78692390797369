import api from '@/api';
import localStorage from '@/utility/localStorage';
import mutationTypes from './mutationTypes';

const setStatusMessage = (commit, options) => {
  const DEFAULT_TIMEOUT = 3000;

  setTimeout(() => {
    commit(mutationTypes.CLEAR_STATUS_MESSAGE);
  }, options.timeout || DEFAULT_TIMEOUT);

  return commit(mutationTypes.SET_STATUS_MESSAGE, options);
};

export const handleError = ({ commit }, err) => {
  const msg = err.response?.data?.message ?? (typeof err === 'string' ? err : 'Something went wrong');

  console.trace();
  setStatusMessage(commit, {
    body: msg,
    type: 'mars',
  });
};

export const handleSuccess = ({ commit }, msg) => {
  setStatusMessage(commit, {
    body: msg || 'Successful action!',
    type: 'earth',
  });
};

export default {
  getInitialAppData: ({ dispatch }) => Promise.all([
    dispatch('getCompanies'),
    dispatch('getCategories'),
    dispatch('getTranslations'),
  ]),
  getTranslations: ({ commit, state }) => {
    api.getTranslations(state.user.language)
      .then((translations) => commit(mutationTypes.SET_TRANSLATIONS, translations))
      .catch((err) => handleError({ commit }, err));
  },
  getLanguages: ({ commit }, params) => api.getLanguages(params)
    .then((languages) => {
      commit(mutationTypes.SET_LANGUAGES, languages);
      return languages;
    })
    .catch((err) => handleError({ commit }, err)),
  clearStatusMessage: ({ commit }) => {
    commit(mutationTypes.CLEAR_STATUS_MESSAGE);
  },
  toggleIsDragging: ({ commit }, bool) => {
    commit(mutationTypes.TOGGLE_IS_DRAGGING, bool);
  },
  toggleLoader: ({ commit }, value) => {
    commit(mutationTypes.TOGGLE_LOADER, value);
  },
  setFilterConfig: ({ commit, state }, name) => {
    if (state[name]) {
      commit(mutationTypes.SET_FILTER_CONFIG, name);
    }
  },
  storeFilterParams: ({ commit }, filterParams) => {
    commit(mutationTypes.SET_FILTER_PARAMS, filterParams);
  },
  handleSuccess,
  handleError,
  setUser: ({ commit }, data) => {
    commit(mutationTypes.SET_USER, data);
    localStorage.setItem('user', JSON.stringify(data));
  },
  setUserModules: ({ commit }, data) => {
    commit(mutationTypes.SET_USER_MODULES, data);
  },
};
