<template>
  <div class="g-alert fluid-container"
       :class="classes">
    <i class="g-alert-icon g-icon g-icon-close-a"
       @click="clearStatusMessage" />
    <div class="g-alert-content">
      {{ statusMessage.body }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ActionMessage',
  computed: {
    ...mapState(['statusMessage']),
    classes() {
      return [
        {
          visible: !!this.statusMessage.body,
        },
        `g-alert-${this.statusMessage.type}`,
      ];
    },
  },
  methods: {
    clearStatusMessage() {
      this.$store.dispatch('clearStatusMessage');
    },
  },
};
</script>

<style lang="scss" scoped>
.g-alert {
  position: fixed;
  z-index: 1000;
  bottom: 50px;
  left: 0;
  display: flex;
  box-shadow: none;
  pointer-events: none;
  border-top-right-radius: $br-sm;
  border-bottom-right-radius: $br-sm;
  opacity: 0;
  transform: translateY(0.75rem);
  transition: opacity 150ms $ease-out-cubic, transform 150ms $ease-out-cubic;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
}

.g-alert-content {
  padding: 1em !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: $br-sm;
}

.g-alert-icon {
  border-top-left-radius: $br-sm;
  border-bottom-left-radius: $br-sm;
  position: relative !important;
}
</style>
