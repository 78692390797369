import api from '../../api';
import mutationTypes from '../mutationTypes';
import { handleError, handleSuccess } from '../actions';

export default {
  state: {
    casinoCarousels: [],
    casinoCarouselTypes: [],
    filterConfig: {
      company: true,
    },
  },

  getters: {
    carouselsFilterConfig: (state) => state.filterConfig,
    casinoCarousels: (state) => state.casinoCarousels,
    casinoCarouselTypes: (state) => state.casinoCarouselTypes,
    getCarouselTypeByName: (state) => (name) => state.casinoCarouselTypes.find((type) => type.name === name),
    getCarouselTypeById: (state) => (id) => state.casinoCarouselTypes.find((type) => type.id === id),
  },

  actions: {
    getCasinoCarousels: ({ commit }, params) => api.getCasinoCarousels(params)
      .then((carousels) => commit(mutationTypes.SET_CASINO_CAROUSELS, carousels))
      .catch((err) => handleError({ commit }, err)),

    getCasinoCarouselTypes: ({ commit }, params) => api.getCasinoCarouselTypes(params)
      .then((carouselTypes) => commit(mutationTypes.SET_CASINO_CAROUSEL_TYPES, carouselTypes))
      .catch((err) => handleError({ commit }, err)),

    createCasinoCarousel: ({ commit, rootState, dispatch }, params) => api
      .createCasinoCarousel(params)
      .then((response) => { handleSuccess({ commit }, response.message); })
      .catch((err) => { handleError({ commit }, err); })
      .finally(() => dispatch('getCasinoCarousels', rootState.filterParams)),

    updateCasinoCarousel: ({ commit, rootState, dispatch }, params) => api
      .updateCasinoCarousel(params)
      .then(() => handleSuccess({ commit }, 'Successfully updated carousel!'))
      .catch((err) => handleError({ commit }, err))
      .finally(() => dispatch('getCasinoCarousels', rootState.filterParams)),

    deleteCasinoCarousel: ({ commit, rootState, dispatch }, params) => api
      .deleteCasinoCarousel(params)
      .then(() => handleSuccess({ commit }, 'Carousel successfully deleted!'))
      .catch((err) => handleError({ commit }, err))
      .finally(() => dispatch('getCasinoCarousels', rootState.filterParams)),

    updateCasinoCarouselsOrder: ({ commit, rootState, dispatch }, params) => api
      .updateCasinoCarouselsOrder(params)
      .catch((err) => handleError({ commit }, err))
      .finally(() => dispatch('getCasinoCarousels', rootState.filterParams)),
  },

  mutations: {
    [mutationTypes.SET_CASINO_CAROUSELS](state, carousels) {
      state.casinoCarousels = carousels;
    },
    [mutationTypes.SET_CASINO_CAROUSEL_TYPES](state, carouselTypes) {
      state.casinoCarouselTypes = carouselTypes;
    },
  },
};
