import http from './http';

const getLanguages = async () => {
  const { data } = await http.get(
    '/casino/admin/languages',
  );

  return data;
};

export default getLanguages;
