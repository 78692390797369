import axios from 'axios';
import { interceptor, responseInterceptor } from './interceptors';

const baseURL = process.env.VUE_APP_WEBADAPTER_API_BASEURL;

const http = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'X-Nsft-SCD-Version': 3,
  },
});

http.interceptors.request.use(interceptor, (error) => Promise.reject(error));
http.interceptors.response.use(responseInterceptor, (error) => Promise.reject(error));

export default http;
