import api from '@/api';
import {
  removeGroupGames,
  toggleGamesGroupMembership,
  toggleGamesIsLoading,
  updateGetGroupGamesParams,
  parseGroupGamesData,
  getGroupErrorTranslations,
} from '@/utility/groups';
import dayjs from '@/utility/dayjs';
import mutationTypes from '../mutationTypes';
import { handleError } from '../actions';

export default {
  state: {
    subgroups: [],
    totalSubgroups: 0,
    allSubgroupGamesData: {},
    addedSubgroupGamesData: {},
    allTempSubgroupGamesData: {},
    addedTempSubgroupGamesData: {},
    totalGamesInSubgroup: 0,
    totalGamesInTempSubgroup: 0,
    isCreateEditSubgroupModalOpen: false,
  },

  getters: {
    totalSubgroups: (state) => state.totalSubgroups,
    allSubgroupGamesData: (state) => state.allSubgroupGamesData,
    addedSubgroupGamesData: (state) => state.addedSubgroupGamesData,
    allTempSubgroupGamesData: (state) => state.allTempSubgroupGamesData,
    addedTempSubgroupGamesData: (state) => state.addedTempSubgroupGamesData,
    totalGamesInSubgroup: (state) => state.totalGamesInSubgroup,
    totalGamesInTempSubgroup: (state) => state.totalGamesInTempSubgroup,
    isCreateEditSubgroupModalOpen: (state) => state.isCreateEditSubgroupModalOpen,
    subgroups: (state) => state.subgroups
      .map((subgroup) => ({
        ...subgroup,
        updatedAt: subgroup.updatedAt ? dayjs(subgroup.updatedAt).format('lll') : 'N/A',
      })),
  },

  actions: {
    toggleIsSubgroupCreateEditModalOpen: ({ commit }, isOpen) => commit(mutationTypes.TOGGLE_IS_CREATE_EDIT_SUBGROUP_MODAL_OPEN, isOpen),

    getAllSubgroupGames: ({ commit }, params) => {
      const apiParams = updateGetGroupGamesParams(params);

      return api
        .getGroupGames(apiParams)
        .then((data) => commit(mutationTypes.SET_ALL_SUBGROUP_GAMES_DATA, data))
        .catch((err) => {
          handleError({ commit }, getGroupErrorTranslations(err));
          return {
            isError: true,
            code: err.response?.data?.code,
          };
        });
    },

    getAddedSubgroupGames: ({ state, commit }, params) => {
      const page = state.addedSubgroupGamesData.pagination?.page;
      const apiParams = updateGetGroupGamesParams(params, page);

      return api
        .getTempGroupGames(apiParams)
        .then((data) => {
          commit(mutationTypes.SET_ADDED_SUBGROUP_GAMES_DATA, {
            data,
            isNextPage: params.isNextPage,
            shouldRefetchAllPages: params.shouldRefetchAllPages,
          });
          if (params.isInitialFetch) {
            commit(mutationTypes.SET_TOTAL_SUBGROUP_GAMES, data.pagination.total);
          }
        })
        .catch((err) => {
          handleError({ commit }, getGroupErrorTranslations(err));
          return {
            isError: true,
            code: err.response?.data?.code,
          };
        });
    },

    getAllTempSubgroupGames: ({ commit }, params) => api
      .getGroupGames(params)
      .then((data) => commit(mutationTypes.SET_ALL_TEMP_SUBGROUP_GAMES_DATA, data))
      .catch((err) => {
        handleError({ commit }, getGroupErrorTranslations(err));
        return {
          isError: true,
          code: err.response?.data?.code,
        };
      }),

    getAddedTempSubgroupGames: ({ state, commit }, params) => {
      const page = state.addedTempSubgroupGamesData.pagination?.page;
      const apiParams = updateGetGroupGamesParams(params, page);

      if (params.isInitialFetch) {
        commit(mutationTypes.SET_TOTAL_TEMP_SUBGROUP_GAMES, 0);
      }

      return api
        .getTempGroupGames(apiParams)
        .then((data) => {
          commit(mutationTypes.SET_ADDED_TEMP_SUBGROUP_GAMES_DATA, {
            data,
            isNextPage: params.isNextPage,
            shouldRefetchAllPages: params.shouldRefetchAllPages,
          });
        })
        .catch((err) => {
          handleError({ commit }, getGroupErrorTranslations(err));
          return {
            isError: true,
            code: err.response?.data?.code,
          };
        });
    },

    getSubgroups: ({ commit }, params) => api
      .getSubgroups(params)
      .then((data) => {
        commit(mutationTypes.SET_SUBGROUPS, data.subgroups);
        commit(mutationTypes.SET_TOTAL_SUBGROUPS, data.subgroups.length);
      })
      .catch((err) => {
        handleError({ commit }, err);
        return {
          isError: true,
        };
      }),

    editSubgroup: ({ commit }, params) => {
      const { subgroupId } = params;

      commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, true);
      commit(mutationTypes.TOGGLE_SUBGROUP_LOADING, { isLoading: true, subgroupId });

      return api
        .editSubgroup(params)
        .then((data) => {
          if (data.totalGamesInGroup) {
            commit(mutationTypes.SET_TOTAL_GROUP_GAMES, data.totalGamesInGroup);
          }
          if (params.fullSubgroupData) {
            commit(mutationTypes.UPDATE_SUBGROUP, params.fullSubgroupData);
          }
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, true);
        })
        .catch((err) => {
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, false);
          handleError({ commit }, getGroupErrorTranslations(err, { entityType: 'subgroup' }));

          return {
            isError: true,
            code: err.response?.data?.code,
          };
        })
        .finally(() => {
          commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, false);
          commit(mutationTypes.TOGGLE_SUBGROUP_LOADING, { isLoading: false, subgroupId });
        });
    },

    restoreGamesListOrder: ({ commit }, params) => api
      .updateGamesListOrder(params)
      .catch(async (err) => {
        handleError({ commit }, err);

        return {
          isError: true,
        };
      }),

    editLocalAddedSubgroupGames: ({ commit }, data) => {
      commit(mutationTypes.SET_ADDED_SUBGROUP_GAMES, data);
    },

    editAddedSubgroupGamesPosition: async ({ commit, dispatch }, params) => {
      const toggleGamesLoading = (isLoading) => {
        commit(mutationTypes.TOGGLE_ADDED_SUBGROUP_GAMES_LOADING, { selectedGameIds: [params.payload.gameId], isLoading });
      };

      toggleGamesLoading(true);

      return api.updateGamesListOrder(params)
        .catch((err) => {
          handleError({ commit }, err);

          return {
            isError: true,
            code: err.response?.data?.code,
          };
        })
        .finally(async () => {
          await dispatch('getAddedSubgroupGames', params.getAddedGamesParams);

          toggleGamesLoading(false);
        });
    },

    editSubgroupPosition: ({ commit, dispatch }, params) => {
      const { groupId, companyId, subgroupId } = params;

      commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, true);
      commit(mutationTypes.TOGGLE_SUBGROUP_LOADING, { isLoading: true, subgroupId });
      return api
        .editSubgroup(params)
        .then(() => {
          dispatch('getSubgroups', { groupId, companyId });
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, true);
        })
        .catch((err) => {
          handleError({ commit }, err);
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, false);
        })
        .finally(() => {
          commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, false);
          commit(mutationTypes.TOGGLE_SUBGROUP_LOADING, { isLoading: false, subgroupId });
        });
    },

    createSubgroup: ({ commit }, params) => {
      commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, true);

      return api
        .createSubgroup(params)
        .then((data) => {
          if (data.totalGamesInGroup) {
            commit(mutationTypes.SET_TOTAL_GROUP_GAMES, data.totalGamesInGroup);
          }
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, true);
        })
        .catch((err) => {
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, false);
          handleError({ commit }, getGroupErrorTranslations(err, { entityType: 'subgroup' }));

          return {
            isError: true,
            code: err.response?.data?.code,
          };
        })
        .finally(() => {
          commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, false);
        });
    },

    deleteSubgroup: ({ commit }, params) => {
      commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, true);

      return api
        .deleteSubgroup(params)
        .then(() => commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, true))
        .catch((err) => {
          handleError({ commit }, err);
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, false);
        })
        .finally(() => {
          commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, false);
        });
    },

    updateSubgroups: ({ commit }, subgroups) => {
      commit(mutationTypes.SET_SUBGROUPS, subgroups);
    },

    updateTempSubgroupGames: ({ commit }, params) => {
      const { selectAll, gameIds, filters } = params.payload;
      const mutateGamesParams = {
        areAllGamesSelected: selectAll,
        selectedGameIds: gameIds,
        excludedGameIds: filters.excluded,
      };

      commit(mutationTypes.TOGGLE_All_TEMP_SUBGROUP_GAMES_LOADING, {
        ...mutateGamesParams,
        isLoading: true,
      });
      commit(mutationTypes.TOGGLE_ADDED_TEMP_SUBGROUP_GAMES_LOADING, {
        ...mutateGamesParams,
        isLoading: true,
      });

      return api
        .updateGroupTempGames(params)
        .then((data) => {
          commit(mutationTypes.TOGGLE_TEMP_SUBGROUP_MEMBERSHIP_GAMES, {
            ...mutateGamesParams,
            action: params.action,
          });
          if (params.action === 'REMOVE') {
            commit(mutationTypes.REMOVE_ADDED_TEMP_SUBGROUP_GAMES, mutateGamesParams);
          }
          commit(mutationTypes.SET_TOTAL_TEMP_SUBGROUP_GAMES, data.totalGamesInGroup);
        })
        .catch((err) => {
          handleError({ commit }, getGroupErrorTranslations(err));

          return {
            isError: true,
            code: err.response?.data?.code,
          };
        })
        .finally(() => {
          commit(mutationTypes.TOGGLE_All_TEMP_SUBGROUP_GAMES_LOADING, {
            ...mutateGamesParams,
            isLoading: false,
          });
          commit(mutationTypes.TOGGLE_ADDED_TEMP_SUBGROUP_GAMES_LOADING, {
            ...mutateGamesParams,
            isLoading: false,
          });
        });
    },

    updateSubgroupGames: ({ commit }, params) => {
      const { selectAll, gameIds, filters } = params.payload;
      const mutateGamesParams = {
        areAllGamesSelected: selectAll,
        selectedGameIds: gameIds,
        excludedGameIds: filters.excluded,
      };

      commit(mutationTypes.TOGGLE_ALL_SUBGROUP_GAMES_LOADING, {
        ...mutateGamesParams,
        isLoading: true,
      });
      commit(mutationTypes.TOGGLE_ADDED_SUBGROUP_GAMES_LOADING, {
        ...mutateGamesParams,
        isLoading: true,
      });

      return api
        .updateGroupTempGames(params)
        .then((data) => {
          commit(mutationTypes.TOGGLE_SUBGROUP_MEMBERSHIP_GAMES, {
            ...mutateGamesParams,
            action: params.action,
          });
          if (params.action === 'REMOVE') {
            commit(mutationTypes.REMOVE_ADDED_SUBGROUP_GAMES, mutateGamesParams);
          }
          commit(mutationTypes.SET_TOTAL_SUBGROUP_GAMES, data.totalGamesInGroup);
        })
        .catch((err) => {
          handleError({ commit }, getGroupErrorTranslations(err));

          return {
            isError: true,
            code: err.response?.data?.code,
          };
        })
        .finally(() => {
          commit(mutationTypes.TOGGLE_ALL_SUBGROUP_GAMES_LOADING, {
            ...mutateGamesParams,
            isLoading: false,
          });
          commit(mutationTypes.TOGGLE_ADDED_SUBGROUP_GAMES_LOADING, {
            ...mutateGamesParams,
            isLoading: false,
          });
        });
    },
  },

  mutations: {
    [mutationTypes.SET_SUBGROUPS](state, data) {
      state.subgroups = data;
    },
    [mutationTypes.UPDATE_SUBGROUP](state, data) {
      state.subgroups = state.subgroups.map((subgroup) => (subgroup.id === data.id ? data : subgroup));
    },
    [mutationTypes.SET_TOTAL_SUBGROUPS](state, total) {
      state.totalSubgroups = total;
    },
    [mutationTypes.SET_ALL_SUBGROUP_GAMES_DATA](state, data) {
      state.allSubgroupGamesData = data;
    },
    [mutationTypes.SET_ADDED_SUBGROUP_GAMES](state, data) {
      state.addedSubgroupGamesData = {
        ...state.addedSubgroupGamesData,
        games: data,
      };
    },
    [mutationTypes.SET_ADDED_SUBGROUP_GAMES_DATA](state, { data, isNextPage, shouldRefetchAllPages }) {
      state.addedSubgroupGamesData = parseGroupGamesData({
        newData: data,
        prevData: state.addedSubgroupGamesData,
        isNextPage,
        shouldRefetchAllPages,
      });
    },
    [mutationTypes.SET_ALL_TEMP_SUBGROUP_GAMES_DATA](state, data) {
      state.allTempSubgroupGamesData = data;
    },
    [mutationTypes.SET_ADDED_TEMP_SUBGROUP_GAMES_DATA](state, { data, isNextPage, shouldRefetchAllPages }) {
      state.addedTempSubgroupGamesData = parseGroupGamesData({
        newData: data,
        prevData: state.addedTempSubgroupGamesData,
        isNextPage,
        shouldRefetchAllPages,
      });
    },

    [mutationTypes.SET_TOTAL_SUBGROUP_GAMES](state, totalGamesInGroup) {
      state.totalGamesInSubgroup = totalGamesInGroup;
    },
    [mutationTypes.SET_TOTAL_TEMP_SUBGROUP_GAMES](state, totalGamesInGroup) {
      state.totalGamesInTempSubgroup = totalGamesInGroup;
    },

    [mutationTypes.REMOVE_ADDED_SUBGROUP_GAMES](state, params) {
      state.addedSubgroupGamesData.games = removeGroupGames({
        games: state.addedSubgroupGamesData.games,
        ...params,
      });
    },
    [mutationTypes.REMOVE_ADDED_TEMP_SUBGROUP_GAMES](state, params) {
      state.addedTempSubgroupGamesData.games = removeGroupGames({
        games: state.addedTempSubgroupGamesData.games,
        ...params,
      });
    },

    [mutationTypes.TOGGLE_TEMP_SUBGROUP_MEMBERSHIP_GAMES](state, params) {
      state.allTempSubgroupGamesData.games = toggleGamesGroupMembership({
        games: state.allTempSubgroupGamesData.games,
        ...params,
      });
    },
    [mutationTypes.TOGGLE_SUBGROUP_MEMBERSHIP_GAMES](state, params) {
      state.allSubgroupGamesData.games = toggleGamesGroupMembership({
        games: state.allSubgroupGamesData.games,
        ...params,
      });
    },

    [mutationTypes.TOGGLE_All_TEMP_SUBGROUP_GAMES_LOADING](state, params) {
      state.allTempSubgroupGamesData.games = toggleGamesIsLoading({
        games: state.allTempSubgroupGamesData.games,
        ...params,
      });
    },
    [mutationTypes.TOGGLE_ADDED_TEMP_SUBGROUP_GAMES_LOADING](state, params) {
      state.addedTempSubgroupGamesData.games = toggleGamesIsLoading({
        games: state.addedTempSubgroupGamesData.games,
        ...params,
      });
    },
    [mutationTypes.TOGGLE_ALL_SUBGROUP_GAMES_LOADING](state, params) {
      state.allSubgroupGamesData.games = toggleGamesIsLoading({
        games: state.allSubgroupGamesData.games,
        ...params,
      });
    },
    [mutationTypes.TOGGLE_ADDED_SUBGROUP_GAMES_LOADING](state, params) {
      state.addedSubgroupGamesData.games = toggleGamesIsLoading({
        games: state.addedSubgroupGamesData.games,
        ...params,
      });
    },

    [mutationTypes.TOGGLE_IS_CREATE_EDIT_SUBGROUP_MODAL_OPEN](state, bool) {
      state.isCreateEditSubgroupModalOpen = bool;
    },
    [mutationTypes.TOGGLE_SUBGROUP_LOADING](state, { isLoading, subgroupId }) {
      state.subgroups = state.subgroups.map((subgroup) => (subgroup.id === subgroupId ? { ...subgroup, isLoading } : subgroup));
    },
  },
};
