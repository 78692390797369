import Vue from 'vue';
import { handleError } from '../actions';
import mutationTypes from '../mutationTypes';
import api from '../../api';

export default {
  state: {
    companies: [],
    categories: [],
    aggregators: [],
    providers: [],
    channels: [
      {
        titleTranslationKey: 'filter_desktop_selector',
        value: 'desktop',
      },
      {
        titleTranslationKey: 'filter_mobile_selector',
        value: 'mobile',
      },
    ],
    activities: [
      {
        title: 'Active',
        value: true,
      },
      {
        title: 'Inactive',
        value: false,
      },
    ],
    statuses: [
      {
        title: 'Enabled',
        value: true,
      },
      {
        title: 'Disabled',
        value: false,
      },
    ],
    gameTypes: [
      {
        title: 'Live',
        value: true,
      },
      {
        title: 'Slot',
        value: false,
      },
    ],
  },

  getters: {
    channels: (state) => state.channels.map((channel) => ({
      ...channel,
      title: Vue.filter('translate')(channel.titleTranslationKey),
    })),
    activities: (state) => state.activities,
    companies: (state) => state.companies,
    gameTypes: (state) => state.gameTypes,
  },

  mutations: {
    [mutationTypes.SET_COMPANIES](state, companies) {
      state.companies = companies;
    },
    [mutationTypes.SET_CATEGORIES](state, categories) {
      state.categories = categories;
    },
    [mutationTypes.SET_AGGREGATORS](state, aggregators) {
      state.aggregators = aggregators;
    },
    [mutationTypes.SET_PROVIDERS](state, providers) {
      state.providers = providers;
    },
  },

  actions: {
    getCompanies: ({ commit }) => api.getCompanies()
      .then((companies) => {
        commit(mutationTypes.SET_COMPANIES, companies);
      })
      .catch((err) => {
        handleError({ commit }, err);
      }),
    getCategories: ({ commit }) => api.getCategories()
      .then((categories) => commit(mutationTypes.SET_CATEGORIES, categories))
      .catch((err) => handleError({ commit }, err)),
    getAggregators: ({ commit, getters }, companyId) => api.getAggregators(companyId ?? getters.getFilterParams.companyId)
      .then((aggregators) => commit(mutationTypes.SET_AGGREGATORS, aggregators))
      .catch((err) => handleError({ commit }, err)),
    getProviders: ({ commit, getters }, companyId) => api.getProviders(companyId ?? getters.getFilterParams.companyId)
      .then((providers) => commit(mutationTypes.SET_PROVIDERS, providers))
      .catch((err) => handleError({ commit }, err)),
  },
};
