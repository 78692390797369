const isSupported = () => {
  const test = 'localStorage';

  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export default {
  isSupported: isSupported(),
  getAllKeys() {
    if (!this.isSupported) return null;

    return Object.keys(localStorage);
  },
  getItem(key) {
    if (!this.isSupported) return null;

    return localStorage.getItem(key);
  },
  removeItem(key) {
    if (!this.isSupported) return null;

    return localStorage.removeItem(key);
  },
  setItem(key, value) {
    if (!this.isSupported) return null;

    localStorage.setItem(key, value);
    return this.getItem(key);
  },
};
