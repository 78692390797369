import http from './http';

const getCasinoCarousels = ({ companyId }) => {
  const url = `/casino/admin/companies/${companyId}/carousels`;

  return http.get(url, {
    params: {
      showInactive: true,
    },
  }).then((res) => res.data);
};

const getCasinoCarouselTypes = () => {
  const url = 'casino/admin/carousels/types';

  return http.get(url)
    .then((res) => res.data);
};

const createCasinoCarousel = (params) => {
  const url = `casino/admin/companies/${params.companyId}/carousels`;

  return http.post(url, params.carousel).then((res) => res.data);
};

const updateCasinoCarousel = (params) => {
  const url = `casino/admin/companies/${params.companyId}/carousels/${params.carousel.id}`;

  return http.put(url, params.carousel);
};

const deleteCasinoCarousel = (params) => {
  const url = `casino/admin/companies/${params.companyId}/carousels/${params.carouselId}`;

  return http.delete(url);
};

const updateCasinoCarouselsOrder = (params) => {
  const url = `casino/admin/companies/${params.companyId}/carousels/order`;

  return http.patch(url, { order: params.order });
};

export default {
  getCasinoCarousels,
  getCasinoCarouselTypes,
  createCasinoCarousel,
  updateCasinoCarousel,
  deleteCasinoCarousel,
  updateCasinoCarouselsOrder,
};
