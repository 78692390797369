const routes = [
  {
    path: 'games/:companyId?',
    name: 'games',
    props: true,
    component: () => import(/* webpackChunkName: "games" */ '../pages/AllGames'),
    meta: { userModule: 'companyAdmin' },
    showInHeader: true,
  },
  {
    path: 'popular/:companyId?',
    name: 'popular',
    props: true,
    component: () => import(/* webpackChunkName: "popular" */ '../pages/PopularGames'),
    meta: { userModule: 'companyAdmin' },
    showInHeader: true,
  },
  {
    path: 'groups/:companyId?',
    name: 'groups',
    props: true,
    component: () => import(/* webpackChunkName: "popular" */ '../pages/Groups'),
    meta: { userModule: 'companyAdmin' },
    showInHeader: true,
  },
  {
    path: 'groups/:companyId/create',
    name: 'groupCreate',
    props: true,
    component: () => import(/* webpackChunkName: "groupCreate" */ '../pages/GroupCreateForm'),
    meta: { userModule: 'companyAdmin' },
    showInHeader: false,
  },
  {
    path: 'groups/:companyId/create/add-games/:tempGroupId',
    name: 'groupCreateAddGames',
    props: true,
    component: () => import(/* webpackChunkName: "groupCreateAddGames" */ '../pages/GroupCreateAddGames'),
    meta: { userModule: 'companyAdmin' },
    showInHeader: false,
  },
  {
    path: 'groups/:companyId/edit/:groupId',
    name: 'groupEdit',
    props: true,
    component: () => import(/* webpackChunkName: "groupEdit" */ '../pages/GroupEdit'),
    meta: { userModule: 'companyAdmin' },
    showInHeader: false,
  },
  {
    path: 'carousels/:companyId?',
    name: 'carousels',
    props: true,
    component: () => import(/* webpackChunkName: "carousels" */ '../pages/Carousels'),
    meta: { userModule: 'companyAdmin' },
    showInHeader: true,
  },
  {
    path: 'tpagames',
    name: 'tpagames',
    props: true,
    component: () => import(/* webpackChunkName: "tpagames" */ '../pages/TPAGames'),
    meta: { userModule: 'tpaGames' },
    showInHeader: true,
  },
  {
    path: 'tpaaggregators',
    name: 'tpaaggregators',
    props: true,
    component: () => import(/* webpackChunkName: "tpagames" */ '../pages/TPAGames'),
    meta: { userModule: 'tpaAggregators' },
    showInHeader: true,
  },
  {
    path: 'tpaproviders',
    name: 'tpaproviders',
    props: true,
    component: () => import(/* webpackChunkName: "tpagames" */ '../pages/TPAGames'),
    meta: { userModule: 'tpaProviders' },
    showInHeader: true,
  },
];

export default routes;
